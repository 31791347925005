/**
 * Facilicom Division javascript
 */
(function($) {
    var FSGDuurzaam = {

        /**
         * Width of desktop size
         */
        largeWidth: 992,
        mediumWidth: 768,
        smallWidth: 640,

        /**
         * On Document ready
         */
        onReady: function() {
            FSGDuurzaam.handleSearchForm();
            FSGDuurzaam.handleNavigationEvents();
            FSGDuurzaam.handleMobileNavigationEvents();
            FSGDuurzaam.handleDropdownLists();
            FSGDuurzaam.handleArticlesOverviewEvents();
            FSGDuurzaam.handleBrandboxEvents();
            FSGDuurzaam.handleIE8Placeholders();
            FSGDuurzaam.handleResponsiveTables();
            FSGDuurzaam.handleShirtofferformEvents();
            FSGDuurzaam.handleDiscussionformEvents();
            FSGDuurzaam.handlePollEvents();
            FSGDuurzaam.handlePillars();

            // Label animation for placeholders in form fields
            jQuery('.js-float-label-wrapper').FloatLabel();

            // Default ajax forms
            jQuery('form.ajax').formlistener();

            jQuery('.more-items-button').moreitemslistener();

            jQuery(".fancybox").fancybox({
                iframe: {
                    preload: false
                }
            });



        },

        /**
         * On window resize do the following actions
         */
        onWindowResize: function() {
            FSGDuurzaam.handleResponsiveTables();
        },


        /**
         * Handles search events
         */
        handleSearchForm: function() {
            jQuery('.small_navigation').on('click', 'a.search', function(event) {
                jQuery('#search_form').toggleClass('open');
                jQuery('.small_navigation a.active:not(.search)').removeClass('active');
            });
            jQuery('.small_navigation').on('click', 'a:not(.main)', function(event) {
                jQuery('.main_navigation.open').removeClass('open');
            });
            jQuery('.small_navigation').on('click', 'a:not(.search)', function(event) {
                jQuery('#search_form').removeClass('open');
            });
        },

        /**
         * Handle navigation events
         */
        handleNavigationEvents: function() {
            jQuery('#main_navigation').on('click', 'li.has_submenu:not(.hover) > a', function (event) {
                event.preventDefault();
                event.stopPropagation();

                var clickedMenuItem = jQuery(this);

                if (jQuery(window).width() > FSGDuurzaam.largeWidth && clickedMenuItem.closest('#main_navigation').length) {
                    // Position a submenu without subsubmenus beneath the link in the main menu
                    // as a list instead of 4 columns
                    var caption = clickedMenuItem.find('> span.menu_caption');
                    if (caption) {
                        var captionPosition = caption.position().left;
                        var submenuPadding = 20;
                        clickedMenuItem.parent().find('.pair:not(:has(ul > li.has_submenu))')
                                .css('left', (captionPosition - submenuPadding) + 'px')
                                .css('width', '24%')
                                .css('float', 'none');
                    }
                }
                // Corporate bar is clicked, dont push down content anymore
                else if(jQuery(window).width() > FSGDuurzaam.largeWidth) {
                    jQuery('#main_navigation').css('marginBottom', 0);
                }

                jQuery('.hover').removeClass('hover').find(' > ul').hide();
                clickedMenuItem.parent().addClass('hover').closest('#main_navigation').removeClass('folded');

                if (jQuery('.ie8').length) {
                    clickedMenuItem.next('ul').show();
                }
                else {
                    var clicked_li_item = clickedMenuItem.closest('li').attr('class');
                    if(clicked_li_item.indexOf('languages') != -1){
                        // get resolution
                        if( jQuery( window ).width() > 1009 ) {
                            clickedMenuItem.next('ul').slideDown("fast");
                        }
                    }else{
                        clickedMenuItem.next('ul').slideDown();
                    }
                }
            });


            // Handle click outside of dropdown
            jQuery(document).on('click', function(event) {
                var clicked_li_item = jQuery('.hover').closest('li').attr('class');
                jQuery('.hover > ul').hide();
                jQuery('.hover').removeClass('hover').closest('#main_navigation').addClass('folded');
                jQuery('#main_navigation').css('marginBottom', 0);
                jQuery('.filter-open').removeClass('filter-open');
            });

            jQuery('.filters').on('click', 'a.question', function(event) {
                event.preventDefault();
                event.stopPropagation();
                jQuery(this).parent().toggleClass('filter-open');
            });

            jQuery('.filters').on('click', '.type_select a', function(event) {
                event.preventDefault();
                event.stopPropagation();
                jQuery('.filters ul').toggleClass('filter-open');
            });
        },

        /**
         * Handle mobile navigation events
         */
        handleMobileNavigationEvents: function() {
            jQuery('.header').on('click', '.toggle', function (event) {
                event.preventDefault();
                var element = jQuery(this);
                // Toggle the correct item
                if (element.hasClass('active')) {
                    element.removeClass('active');
                    jQuery(element.attr('href')).removeClass('open');
                } else {
                    jQuery('.active', '.header').removeClass('active');
                    element.addClass('active');
                    jQuery('.open', '.header').removeClass('open');
                    jQuery(element.attr('href')).addClass('open');
                }
                return false;
            });
        },


        /**
         * Dropdown list are loaded as <ul>, so some event listeners are needed
         */
        handleDropdownLists: function() {
            jQuery(document).on('click', '.formfield.select_field .f-dropdown li', function() {
                var formfield = jQuery(this).closest('.formfield');
                // Set active option
                jQuery('.button.dropdown .active-option', formfield).text(jQuery(this).text());
                // Set hidden value
                jQuery('input[type="hidden"]', formfield).val(jQuery(this).attr('item-id'));
                // Close the dropdown list
                Foundation.libs.dropdown.close(jQuery('ul', formfield));
                jQuery('ul', formfield).trigger('foundation-dropdown-closed');
            });
        },


        /**
         * Handle articles overview events
         */
        handleArticlesOverviewEvents: function() {
            // Ajax result shouldn't replace the form but replace the article list
            jQuery('form.yearform_form, form.dishtypeform_form').formlistener({
                beforeFormSubmit: function(form) {
                    form.closest('.articlesoverview').append('<div class="ajax-loader"/>');
                },
                afterFormSubmit: function(form, html) {
                    form.closest('.articlesoverview')
                        .addClass('animating')
                        .find('.ajax-loader').remove();

                    jQuery('.articles-list', form.closest('.articlesoverview')).slideUp(function() {
                        jQuery('.articles-list', form.closest('.articlesoverview')).html(html).slideDown(function() {
                            form.closest('.articlesoverview').removeClass('animating');
                        }, 'easeInOutExpo');
                    });
                }
            });
            // Yearforms should be submitted on year change
            jQuery('form.yearform_form .formfield.select_field, form.dishtypeform_form .formfield.select_field').on('foundation-dropdown-closed', 'ul', function() {
                jQuery(this).closest('form').submit();
            })

        },

        /**
         * Handle brandbox events
         */
        handleBrandboxEvents: function() {
            jQuery('.brandbox .orbit-bullets').on('mouseover', 'li', function() {
                var hoveredBullet = jQuery(this);
                if(jQuery('.preview', hoveredBullet).length === 0) {
                    var brandbox = hoveredBullet.closest('.brandbox');
                    var slideIndex = hoveredBullet.attr('data-orbit-slide');
                    var hoveredSlide = jQuery('.orbit-slides-container li:eq(' + slideIndex + ')', brandbox);

                    var title = hoveredSlide.find('input[name="previewtitle"]').val()
                    var image = hoveredSlide.find('input[name="previewimage"]').val();

                    hoveredBullet.append("<div class='preview'><span>" + title + "</span><img src='" + image + "'/><div class='arrow'></div></div>");
                }
            });
            if ($('.orbit-slides-container li').length <= 1) {
                $('.orbit-container').addClass('hide-progressbar');
            }
        },

        /**
         *
         */
        handleIE8Placeholders: function() {
            if(jQuery('.ie8').length) {
                jQuery('input[placeholder]', '#search_form').each(function() {
                    var field = jQuery(this);
                    if(this.value === '') {
                        field.val(field.attr('placeholder')).addClass('active-placeholder');
                    }

                    field.on('click', function() {
                        field.val('').removeClass('active-placeholder').off('click');
                    })
                })
            }
        },

        /**
         *
         */
        handleResponsiveTables: function() {
            if(!jQuery('.responsive-tables-loaded').length && Modernizr.mq('(max-width: ' + FSGDuurzaam.smallWidth + 'px)') ) {
                jQuery('.contentblockcontent.richtext table', '.article-content').each(function(table) {
                    var headerColumns = jQuery('thead td', table).map(function() {
                        return this.innerHTML;
                    });

                    jQuery('td', table).each(function() {
                        var index = jQuery(this).prevAll('td').length;
                        if(typeof(headerColumns[index]) !== 'undefined') {
                            jQuery(this).prepend('<label>' + headerColumns[index] + '</label>');
                        }
                    })

                    jQuery('tr:odd', table).addClass('odd');
                })

                jQuery('body').addClass('responsive-tables-loaded');
            }
        },

        /**
         *
         */
        handleShirtofferformEvents: function() {
            if(jQuery('form.shirtofferform_form').length) {
                jQuery('form.shirtofferform_form').formlistener({
                    afterFormSubmit: FSGDuurzaam.afterShirtofferformSubmit
                });

                jQuery(document).on('click', '#shirtofferform-container .radio_field .option_field', function() {
                    jQuery(this).closest('.radio_field').next('.radio_field').find('input:checked').attr('checked', false);
                    jQuery(this).closest('.radio_field').prev('.radio_field').find('input:checked').attr('checked', false);
                });
                jQuery(document).on('click', 'button[name="check_employee_number"]', function(event) {
                    event.preventDefault();
                    $form = jQuery(this).closest('form');

                    var formData = FSGDuurzaam.getFormData($form)
                        .replace('Wmshirtofferformfsgduurzaam=send', 'Wmshirtofferformfsgduurzaam=getEmployeeDetails');

                    $.ajax({
                        type: 'post',
                        url: 'index.php',
                        data: formData,
                        success: function(html) {

                            // Replace form
                            $form.closest('#shirtofferform-container').wrap('<div class="ajax-call-wrapper"></div>');
                            var ajaxCallWrapper = $form.closest('.ajax-call-wrapper');
                            ajaxCallWrapper.html(html);

                            form = ajaxCallWrapper.find('form');
                            ajaxCallWrapper.find('> *').unwrap();

                            form.formlistener({
                                afterFormSubmit: FSGDuurzaam.afterShirtofferformSubmit
                            });

                            // Reset possible float labels
                            jQuery('.js-float-label-wrapper', form).FloatLabel();

                            // Reset possible foundation dropdowns
                            Foundation.libs.dropdown.init(jQuery('ul.f_dropdown', form));
                        },
                        datatype: 'html'
                    });
                });


            }
        },

        getFormData: function(form) {
            return jQuery('input,textarea', form).map(function(i, formfield) {
                // Filter out the float label input fields that aren't filled in
                var isUnpopulatedFloatLabelField = jQuery(formfield).parent('.formfield').hasClass('js-float-label-wrapper') && !jQuery(formfield).parent('.formfield').hasClass('populated');
                var isUncheckedRadiobox = formfield.type === 'radio' && !formfield.checked;

                if(isUnpopulatedFloatLabelField) {
                    return formfield.name + '=';
                }
                else if(isUncheckedRadiobox) {
                    return '';
                }
                return formfield.name + '=' + jQuery(formfield).val();
            }).get().join('&');
        },

        /**
         *
         */
        afterShirtofferformSubmit: function(form, html) {
            // Replace form
            form.wrap('<div class="ajax-call-wrapper"></div>');
            var ajaxCallWrapper = form.closest('.ajax-call-wrapper');
            ajaxCallWrapper.html(html);

            form = ajaxCallWrapper.find('form');
            ajaxCallWrapper.find('> *').unwrap();

            form.formlistener({
                afterFormSubmit: FSGDuurzaam.afterShirtofferformSubmit
            });

            // Reset possible float labels
            jQuery('.js-float-label-wrapper', form).FloatLabel();

            // Reset possible foundation dropdowns
            Foundation.libs.dropdown.init(jQuery('ul.f_dropdown', form));
        },


        /**
         *
         */
        handleDiscussionformEvents: function() {
            jQuery('.button-wrapper a', '.new-discussion-button-block').on('click', function() {
                jQuery('.start-dicussion-form-wrapper').show();
                jQuery('.new-discussion-button-block-wrapper').hide();
            })
        },

        /**
         *
         */
        handlePollEvents: function() {
            jQuery('.poll .answersform_form').formlistener({
                afterFormSubmit: function(form, html) {
                    // Replace form
                    form.closest('.poll').wrap('<div class="ajax-call-wrapper"></div>');
                    var ajaxCallWrapper = form.closest('.ajax-call-wrapper');
                    ajaxCallWrapper.html(html).find('> *').unwrap();
                    jQuery('#poll-block').addClass('show-results');
                }
            });

            jQuery('.poll').on('click', '.view-poll-results-link', function() {
                jQuery(this).closest('#poll-block').addClass('show-results');
            });
            jQuery('.poll').on('click', '.back-to-form a', function() {
                jQuery(this).closest('#poll-block').removeClass('show-results');
            });
        },

        /**
         * Handle pillars in brandbox
         */
        handlePillars: function() {
            var pillarArray = [];
            for (i = 0; i < 5; i++) {
                pillarArray[i] = jQuery('.pillar' + i + '_image img').attr('src');
            }

            for (d = 0; d < 5; d++) {
                if (pillarArray[d] !== undefined) {
                    jQuery('.pillar' + d + '_image').css({
                        'background-image': 'url("' + pillarArray[d] + '")',
                        'background-repeat': 'no-repeat',
                        'background-size': 'cover'
                    });
                }
            }


        }
    };

    jQuery(document)
        .ready(FSGDuurzaam.onReady)
        .foundation({
            orbit: {
                timer_speed: 8000,
                animation_speed: 1000,
                animation: 'slide',
                easing: 'easeInOutElastic',
                slide_number: false,
                resume_on_mouseout: true,
                variable_height: true,
                after_slide_change: function(slideIndex) {
                    jQuery('li.animation-finished', '.orbit-container').removeClass('animation-finished');
                    jQuery('.orbit-container')
                        .find('li:eq(' + slideIndex + ')')
                        .addClass('animation-finished');
                }
            }
        });
    if(!jQuery('.ie8').length) {
        jQuery(window).resize(FSGDuurzaam.onWindowResize);
    }
}(jQuery));
