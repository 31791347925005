(function($) {
    var FormListener = function(element, options) {

        /**
         * Check if form uses float labels
         */
        var checkIfFormHasFloatLabels = function() {
            if($('.js-float-label-wrapper', form).length > 0) {
                formHasFloatLabels = true;
            }
        }

        /**
         * Binds all event listeners to this form
         */
        var bindEventListeners = function() {
            form.on('submit', function(event) {
                event.preventDefault();
                submitForm(event);
            })
        };

        /**
         * Calls actions for form submit
         * @param {object} event
         */
        var submitForm = function(event) {
            settings.beforeFormSubmit(form);
            $.ajax({
                type: $(form).attr('method'),
                url: $(form).attr('action'),
                data: getFormData(),
                success: function(html) {
                    settings.afterFormSubmit(form, html);
                },
                datatype: 'html'
            });


        }

        /**
         * Retrieves form data
         * @param {object} event
         */
        var getFormData = function() {
            var formData = $('input,textarea', form).map(function(i, formfield) {
                // Filter out the float label input fields that aren't filled in
                var isUnpopulatedFloatLabelField = $(formfield).parent('.formfield').hasClass('js-float-label-wrapper') && !$(formfield).parent('.formfield').hasClass('populated');
                var isUncheckedCheckbox = formfield.type === 'checkbox' && !formfield.checked;
                var isUncheckedRadiobox = formfield.type === 'radio' && !formfield.checked;

                if(isUnpopulatedFloatLabelField || isUncheckedCheckbox) {
                    return formfield.name + '=';
                }
                else if(isUncheckedRadiobox) {
                    return '';
                }
                return formfield.name + '=' + $(formfield).val();
            }).get().join('&');

            return formData + '&dt=ajax&wmtrigger[]=requestufts';
        }

        /**
         * Possible callback function before posting form
         * @param {object} form
         */
        var beforeFormSubmit = function(form) {}

        /**
         * Puts form submit result in DOM 
         * @param {object} form
         * @param {string} html
         */
        var afterFormSubmit = function(form, html) {
            // Replace form
            form.wrap('<div class="ajax-call-wrapper"></div>');
            var ajaxCallWrapper = form.closest('.ajax-call-wrapper');
            ajaxCallWrapper.html(html);
            
            form = ajaxCallWrapper.find('form');
            ajaxCallWrapper.find('> *').unwrap();

            form.formlistener(settings);

            // Reset possible float labels
            if(formHasFloatLabels) {
                $('.js-float-label-wrapper', form).FloatLabel();
            }

            // Reset possible foundation dropdowns
            Foundation.libs.dropdown.init($('ul.f_dropdown', form));
        }

        var form = $(element);
        var obj = this;
        var formHasFloatLabels = false;
        var settings = $.extend({
            beforeFormSubmit: beforeFormSubmit,
            afterFormSubmit: afterFormSubmit
        }, options || {});

        checkIfFormHasFloatLabels();
        bindEventListeners();
    };

    $.fn.formlistener = function(options) {
        return this.each(function() {
            var element = $(this);

            if (element.data('formlistener')) {
                return;
            }

            var formlistener = new FormListener(this, options);
            element.data('formlistener', formlistener);
        });
    };
})(jQuery);
