/**
 * Contact information page
 * 
 * Maps to display a map with contact information
 */
(function($) {
    var facilicomDivisionMaps = {
        /**
         * Initialize the maps
         * @returns {undefined}
         */
        initialize: function() {
            if(!$('.contact_page').length) {
                return;
            }

            // Add map main
            facilicomDivisionMaps.addMap($('.main .map'));

            // Add toggle function to sublocation contact information blocks
            $('.contact_information_per_location').on('click', '.toggle', function() {
                var contact = $(this).parent();
                contact.toggleClass('open');
                // Add Google map, ony the first time
                var map = contact.find('.map');
                if (!map.html()) {
                    facilicomDivisionMaps.addMap(map);
                }
            });
        },

        /**
         * Add a google map
         *
         * @todo Check if map is already open
         * @param jQuery element map
         * @returns {undefined}
         */
        addMap: function(map) {
            var latitude = $(map).attr('data-latitude');
            var longitude = $(map).attr('data-longitude');
            var locationLatLng = new google.maps.LatLng(latitude, longitude);

            var mapOptions = {
                center: locationLatLng,
                zoom: 15,
                disableDefaultUI: true,
                scrollwheel: false
            };
            var gmap = new google.maps.Map($(map).get(0), mapOptions);

            var infobox = new InfoBox({
                content: $(map).parent().find(".info_window").html(),
                disableAutoPan: true,
                closeBoxURL: "",
                pixelOffset: new google.maps.Size(5, -65)
            });
            var marker = new google.maps.Marker({
                position: locationLatLng,
                map: gmap
            });
            infobox.open(gmap,marker);
        }
    };

    $(document).ready(facilicomDivisionMaps.initialize);
}(jQuery));