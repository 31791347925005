(function($) {
    var MoreitemsListener = function(element) {


        /**
         * Binds all event listeners
         */
        var bindEventListeners = function() {
            button.on('click', 'a', function(event) {
                event.preventDefault();
                loadMoreItems(event);
            })
        };

        /**
         * Calls actions for button click
         * @param {object} event
         */
        var loadMoreItems = function(event) {
            $.ajax({
                type: 'post',
                url: '/index.php',
                data: getPostData(),
                success: function(html) {
                    handleData(html);
                },
                datatype: 'html'
            });


        }

        /**
         * Retrieves post data
         * @param {object} event
         */
        var getPostData = function() {
            return 'ct=wmdynamic&dt=ajax&wmtrigger[]=requestufts&module=Wmvideofsgduurzaam&offset=' +  button.find('input[name="offset"]').val() + '&count=' +  button.find('input[name="count"]').val();
        }


        /**
         * Handles data
         * @param {string} html
         */
        var handleData = function(html) {
            // Replace button
            button.wrap('<div class="ajax-call-wrapper"></div>');
            var ajaxCallWrapper = button.closest('.ajax-call-wrapper');
            ajaxCallWrapper.html(html);
            
            button = ajaxCallWrapper.find('.more-items-button');
            $('.more-items-list').append(ajaxCallWrapper.find('.more-items-item'));
            ajaxCallWrapper.find('> *').unwrap();

            button.moreitemslistener();
        }

        var button = $(element);
        var obj = this;

        bindEventListeners();
    };

    $.fn.moreitemslistener = function() {
        return this.each(function() {
            var element = $(this);

            if (element.data('moreitemslistener')) {
                return;
            }

            var moreitemslistener = new MoreitemsListener(this);
            element.data('moreitemslistener', moreitemslistener);
        });
    };
})(jQuery);
